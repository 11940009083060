<template>
    <div class="Navbar" :class="{ m_nav: $store.state.screenType }">
        <div class="navbar">
            <div class="nav-logo" @click="logoClick">
                <img
                    src="../../assets/logo.png"
                    alt="首页logo"
                    v-if="!$store.state.language"
                />
                <img
                    src="../../assets/logo_01.png"
                    alt="首页logo"
                    v-if="$store.state.language"
                />
            </div>
            <div class="nav">
                <div class="nav_menu" v-if="!$store.state.screenType">
                    <div
                        class="nav_menu_item"
                        v-for="value in $t('Nav')"
                        :key="value.id"
                        @mouseenter="mouseEnter(value.id)"
                        @mouseleave="mouseLeave"
                        v-if="!value.hide"
                    >
                        <div class="items" @click="routerClick(value.id)">
                            <span
                                :class="{
                                    active:
                                        menuType === value.id ||
                                        $store.state.navType === value.id,
                                }"
                            >
                                {{ value.name.toUpperCase() }}
                            </span>
                        </div>
                        <transition v-if="value.menuList" v-else="">
                            <ul
                                class="items_child"
                                :style="{
                                    display:
                                        menuType === value.id
                                            ? 'block'
                                            : 'none',
                                }"
                            >
                                <li
                                    v-for="(val, index) in value.menuList"
                                    :key="index"
                                    :class="{ en_font: $store.state.language }"
                                    @click.prevent="childClick(value.id, index)"
                                >
                                    <span>{{ val.toUpperCase() }}</span>
                                    <span class="next"></span>
                                </li>
                            </ul>
                        </transition>
                    </div>
                </div>
                <div class="language">
                    <div
                        class="nav-language"
                        v-if="!$store.state.screenType"
                        @click="langType = !langType"
                    >
                        <span v-show="$store.state.lang !== 'en'">中</span>
                        <span v-show="$store.state.lang !== 'zh'">EN</span>
                    </div>
                    <div
                        class="language_state"
                        v-show="langType"
                        v-if="!$store.state.screenType"
                    >
                        <p @click="languageChange(1)">
                            <span
                                :class="{ active: $store.state.lang === 'zh' }"
                                >简体</span
                            >
                        </p>
                        <p @click="languageChange(2)">
                            <span
                                :class="{ active: $store.state.lang === 'en' }"
                                >EN</span
                            >
                        </p>
                    </div>
                    <div
                        v-if="$store.state.screenType"
                        class="button"
                        @click="phoneLanguage"
                    >
                        <span v-show="$store.state.lang === 'zh'">中</span>
                        <span v-show="$store.state.lang === 'en'">EN</span>
                    </div>
                </div>
                <div class="nav_indicate" @click="menuChange">
                    <div>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
        <!--移动端导航栏-->
        <div class="bar" :class="{ active_flag: flag }">
            <span class="off" @click="flag = false"></span>
            <div class="menu_list">
                <div
                    class="menu_list_item"
                    v-for="value in $t('Nav')"
                    :key="value.id"
                    @click="routerClick(value.id)"
                    :class="{ actives: $store.state.navType === value.id }"
                >
                    <div class="items">
                        <span>
                            {{ value.name.toUpperCase() }}
                        </span>
                        <span
                            class="next"
                            :class="{
                                actives: $store.state.navType === value.id,
                            }"
                            @click="index = value.id"
                        ></span>
                    </div>
                    <transition v-if="value.id !== 4">
                        <!--:style="{'display':(menuType===value.id)?'block':'none'}"-->
                        <div
                            class="items_child"
                            :class="{ active: index === value.id }"
                        >
                            <span class="off" @click="index = -1"></span>
                            <div
                                v-for="(val, index) in value.menuList"
                                :key="index"
                                @click="childClicks(index)"
                                :class="{ en_child: $store.state.language }"
                            >
                                <span> {{ val.toUpperCase() }}</span>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Navbar",
    data() {
        return {
            menuType: 0,
            //路由列表
            routerList: ["/introduce", "/business", "/staff", "", "/media"],

            flag: false, //移动端导航栏状态，当为false时，隐藏，当为true时，显示
            index: -1, //二级菜单的显示与隐藏
            langType: false, //语言切换是否显示个隐藏
            languageIndex: 1, //语音的索引
            //单机语言切换索引
            //lang: -1
        };
    },
    methods: {
        //logo点击事件
        logoClick() {
            this.$router.push("/home");
        },
        //鼠标移入
        mouseEnter(id) {
            this.menuType = id;
        },
        //鼠标移出
        mouseLeave() {
            this.menuType = 0;
        },
        //点击导航栏路由跳转
        routerClick(id) {
            if (id === 4) {
                //this.$store.commit('changeType', id)
            } else if (id === 3) {
                if(this.languageIndex==1){
                    location.href = 'https://svvq8986yua.jobs.feishu.cn/931063'
                }else{
                    location.href = 'https://svvq8986yua.jobs.feishu.cn/931063'
                }
            } else {
                this.$router.push(this.routerList[id - 1]);
                this.$store.commit("changeType", id);
                this.$store.commit("changeIndex", -1);
            }
        },

        childClick(id, index) {
            console.log(`跳转 ${this.$route.path} ==> ${this.routerList[id - 1]} : ${index}`)
            if (this.$route.path == this.routerList[id - 1]) {
                this.$emit("barClick", index);
            } else {
                this.$router.push(this.routerList[id - 1]);
                this.$store.commit('changeIndex', index)
                this.$nextTick(() => {
                    this.$emit("barClick", index);
                })
            }
            //this.$store.commit('changeIndex', index)
            // this.$emit("barClick", index);
        },
        //移动端二级菜单点击
        childClicks(index) {
            this.index = -1;
            this.$emit("barClick", index);
            this.flag = !this.flag;
        },
        //展开移动端导航栏
        menuChange() {
            this.flag = !this.flag;
        },
        //语言切换
        languageChange(index) {
            if (index === 1) {
                this.languageIndex = 1;
                this.$i18n.locale = "zh";
                this.$store.commit("changeLanguage", [false, 1]);
                this.$store.commit("changeLang", "zh");
            }
            if (index === 2) {
                this.languageIndex = 2;
                this.$i18n.locale = "en";
                this.$store.commit("changeLanguage", [true, 2]);
                this.$store.commit("changeLang", "en");
            }
            this.langType = !this.langType;
            //将用户设置存储到localStorage以便用户下次打开时使用此设置
            localStorage.setItem("languageSet", this.$i18n.locale);
        },
        phoneLanguage() {
            let index = 0;
            if (this.$store.state.lang === "zh") {
                index = 2;
            } else if (this.$store.state.lang === "en") {
                index = 1;
            }
            //console.log(index)
            this.languageChange(index);
        },
    },
    mounted() {
        // 简易的中英文适配，会覆盖 languageSet 的选项
        if (!this.$store.state.languageSet) {
            console.log('init lang');
            let browserLang = navigator.language?navigator.language:navigator.browserLanguage;
            browserLang = (browserLang || '').toLowerCase();
            this.languageChange((browserLang==='zh' || browserLang==='zh-cn' || browserLang==='zh-tw')?1:2);
            // 默认是 false, languageChange 会 !这个值
            this.langType = false;
        }
    },
};
</script>


<style scoped lang="scss">
/*-- -------Navbar---------- --*/
.Navbar {
    //font-size: 1rem;
    width: 100%;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    @media screen and (min-width: 768px) {
        font-size: 1rem !important;
    }

    .navbar {
        width: 100%;
        max-width: 1920px;
        height: 6.25rem;
        background-color: #000;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        .nav-logo {
            width: 31.875rem;
            height: 100%;
            padding: 2.3125rem 0 1.625rem 0;
            box-sizing: border-box;
            cursor: pointer;

            img {
                margin: 0 auto;
                display: block;
                width: 30%;
            }
        }

        .nav {
            display: flex;

            &_menu {
                display: flex;
                align-items: center;

                &_item {
                    position: relative;
                    height: 6.25rem;

                    .items {
                        width: 7.5rem;
                        line-height: 6.25rem;
                        //width: 5.4375rem;
                        font-size: 1.625rem;
                        text-align: center;
                        color: #ffffff;
                        cursor: pointer;
                        //padding: 0 1.625rem;
                    }

                    .items_child {
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: 1.625rem;
                        width: 23.0625rem;
                        background-color: #1f1f1f;
                        color: #e1e1e1;
                        font-size: 1.25rem;
                        font-weight: lighter;
                        padding: 0 1.5rem;
                        line-height: 2.0625rem;
                        z-index: 100;
                        font-family: "AlibabaPuHuiTiL";

                        li {
                            height: 4.0625rem;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 0.125rem solid #3d3c3b;
                            cursor: pointer;

                            span {
                                //max-width: 18rem;
                                display: block;
                                width: 90%;
                            }

                            .next {
                                width: 1.4375rem;
                                height: 0.9375rem;
                                background-image: url("../../assets/next_l_02.png");
                                background-size: 100% auto;
                            }

                            &:last-child {
                                border: none;
                            }

                            &:hover {
                                .next {
                                    background-image: url("../../assets/next_02.png");
                                }
                            }
                        }
                    }

                    .active {
                        position: relative;

                        &:before {
                            position: absolute;
                            top: 104%;
                            left: -3%;
                            content: "";
                            width: 106%;
                            height: 0.125rem;
                            background-color: red;
                            border-radius: 0.125rem;
                        }

                        &:after {
                            position: absolute;
                            content: "";
                            width: 0;
                            height: 0;
                            top: 104%;
                            left: calc(52% - 0.15625rem);
                            border-style: solid;
                            border-width: 0.3125rem 0.3125rem 0 0.3125rem;
                            border-color: red transparent transparent
                                transparent;
                        }
                    }

                    &:last-child {
                        .items_child {
                            right: 0;
                            left: auto;
                        }
                    }
                }

                @media screen and (max-width: 980px) {
                    &_item {
                        //background-color: #fff;
                    }
                }
            }

            .language {
                width: 12.5rem;
                height: 100%;
                position: relative;

                .nav-language {
                    width: 1.9375rem;
                    height: 1.9375rem;
                    border-radius: 50%;
                    background-color: #fff;
                    color: #ed111c;
                    text-align: center;
                    line-height: 1.9375rem;
                    font-weight: 800;
                    font-size: 1rem;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }

                &_state {
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    border-radius: 0.25rem;
                    //line-height: 6.25rem;
                    //display: flex;
                    //justify-content: space-around;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background-color: #000000;
                    text-align: center;
                    animation: slide-bottom 0.5s
                        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                    @keyframes slide-bottom {
                        0% {
                            -webkit-transform: translateY(-6.25rem);
                            transform: translateY(-6.25rem);
                            opacity: 0;
                        }
                        100% {
                            -webkit-transform: translateY(0);
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }

                    p {
                        font-size: 1.5rem;
                        cursor: pointer;
                        line-height: 3.125rem;

                        .active {
                            position: relative;

                            &:before {
                                position: absolute;
                                top: 104%;
                                left: -3%;
                                content: "";
                                width: 106%;
                                height: 0.125rem;
                                background-color: red;
                                border-radius: 0.125rem;
                            }

                            &:after {
                                position: absolute;
                                content: "";
                                width: 0;
                                height: 0;
                                top: 104%;
                                left: calc(52% - 0.15625rem);
                                border-style: solid;
                                border-width: 0.3125rem 0.3125rem 0 0.3125rem;
                                border-color: red transparent transparent
                                    transparent;
                            }
                        }
                    }
                }

                .button {
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 1.25rem;
                    background-color: #2d2d2d;
                    position: absolute;
                    top: 1.875rem;
                    right: 0;
                    color: #fff;
                    line-height: 2.5rem;
                    text-align: center;
                    font-size: 1rem;
                }
            }
            @media screen and (max-width: 980px) {
                .language {
                    width: 7.5rem;
                }
            }
        }
    }

    .bar {
        position: absolute;
        top: 6.25rem;
        right: 0;
        width: 21.0625rem;
        display: none;
        background-color: #fff;
        color: #000;
        padding: 1rem 2.25rem;

        .off {
            width: 1.25rem;
            height: 1.375rem;
            background-image: url("../../assets/m_04.png");
            background-size: 100% auto;
            background-repeat: no-repeat;
            display: block;
            z-index: 999;
            padding: 1rem 0;
        }

        .menu_list {
            width: 100%;
            height: 100%;
            //padding: 1.875rem;
            box-sizing: border-box;

            &_item {
                font-size: 1.25rem;
                height: auto;

                .items {
                    height: 5.0625rem;
                    line-height: 4.0625rem;
                    position: relative;

                    .next {
                        width: 0.8125rem;
                        height: 1.5625rem;
                        //display: block;
                        background-image: url("../../assets/m_05.png");
                        background-size: 100% auto;
                        position: absolute;
                        top: calc(50% - 0.78125rem);
                        right: 0;
                        display: none;
                    }

                    .actives {
                        display: block;
                    }
                }

                .items_child {
                    width: 100%;
                    //height: auto;
                    min-height: 29.625rem;
                    position: absolute;
                    left: 100%;
                    top: 0;
                    background-color: #fff;
                    //padding: 4.875rem 4.125rem;
                    padding: 1rem 2.25rem;
                    box-sizing: border-box;
                    z-index: 100;
                    transition: all 0.5s;
                    font-family: "AlibabaPuHuiTiL";

                    div {
                        width: 100%;
                        height: 5.0625rem;
                        line-height: 4.0625rem;
                    }

                    .en_child {
                        height: auto;
                        min-height: 5.0625rem;
                        line-height: 2.0625rem;
                        font-size: 1rem;
                        padding: 0.375rem 0;
                    }
                }

                .active {
                    left: 0;
                }
            }

            .actives {
                //background-color: #ED121B;
            }
        }
    }

    .active_flag {
        display: block;
    }

    .en_font {
        min-height: 4.0625rem;
        height: auto !important;
        font-size: 0.75rem !important;
        padding: 0.375rem 0;
    }
}

@import "m-navbar";
</style>
